import React, { useEffect, useState } from "react";
import styles from "./index.scss";
import { Typography, message } from "antd";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { getSearch } from "~/utils";
import { get } from "~/request";
import { request } from "../../request";
import { saveAs } from "file-saver";
import axios from "axios";
const { Paragraph } = Typography;
const txt = {
  en: {
    tips: [
      "After logging in, you can choose the relevant field and view the ratings of ",
      " our testing program ",
      "for 5 papers.",
    ],
    rules: "Rating Rules:",
    first: [
      "Please fill in the table to indicate whether the analysis results of ",
      " our testing program ",
      "in five dimensions are reasonable:",
      "Options include: 1. Very Reasonable, 2. Reasonable, 3. Unreasonable",
      "The five evaluation dimensions of",
      " our testing program ",
      "are as follows:",
      "Problem: Is the problem presented in the paper original, sharp, and valuable?",
      "Theoretical Method: Do you subjectively believe that the theoretical method proposed for the problem is suitable?",
      "Practical Method: Do you think the practical method derived from the theoretical method can effectively solve the problem?",
      "Effects: Are you satisfied with the effects produced by the theoretical and practical methods for the problem?",
      "Conclusion: Can the conclusion lead to many subfields and guide future innovation?",
    ],
    secend:
      "You do not need to click save; it will be automatically recorded in the background.",
    third: ["If needed, you can ", "upload other articles", " for rating."],
    download: ["You can", " click here to download ", "all rating reports"],
    end: "The papers on the test website are fetched through legitimate channels and are only used for testing purposes.",
  },
  cn: {
    tips: [
      "登录后，您可选择与您相关的领域，查看",
      "独立品牌",
      "对5篇论文的评分。",
    ],
    rules: "评分规则：",
    first: [
      "请在表格中填写您认为",
      "独立品牌",
      "的五个维度分析结果是否合理。",
      "选项包括：1. 非常合理、2. 合理、3. 不合理",
      "",
      "独立品牌",
      "的五个评价维度分别是：",
      "问题：该论文提出的问题是否为首次提出，是否尖锐、有价值",
      "理论方法：针对问题提出的理论方法，您主观认为是否符合该问题",
      "实践方法：从理论方法中进行的实践，您认为是否能有效解决问题",
      "效果：针对问题，您对理论方法、实践方法产生的效果是否满意",
      "结论：结论是否能产生很多细分领域，引导未来创新",
    ],
    secend: "您无需点击保存，后台会自动记录。",
    third: ["如有需要，您可以", "上传其他文章", "进行评分。"],
    download: ["您可以", "点此下载", "所有评分报告"],
    end: "测试网站论文均从正规途径下载，仅用作测试用途。",
  },
};

export default function Home({ languageType }) {
  const location = useLocation();
  const [text, setText] = useState(txt.cn);
  useEffect(() => {
    if (getSearch(location.search)) {
      getSearch(location.search).lang == "en"
        ? setText(txt.en)
        : setText(txt.cn);
    }
  }, [location.search]);
  useEffect(() => {
    if (languageType) {
      setText(languageType == "cn" ? txt.cn : txt.en);
    }
  }, [languageType]);

  const download = () => {
    console.log(window.token, window.userId);
    const hide = message.loading('文件创建中...', 0);
    fetch(`${axios.defaults.baseURL}/paper/analysis/export`, {
      method: "get",
      headers: {
        "X-Token": window.token,
        "X-User-Id": window.userId,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        hide()
        saveAs(blob, "五维评分信息.xlsx");
      });
  };
  return (

      <div className={styles.home}>
        {text && (
          <Paragraph>
            <h2>CUHK-Shenzhen test program</h2>
            <br />
            {text.tips[0]}
            <span style={{ color: "#0075bb" }}>{text.tips[1]}</span>
            {text.tips[2]}
            <br />
            <h3>{text.rules}</h3>
            <ol>
              <li>
                {text.first[0]}
                <span style={{ color: "#0075bb" }}>{text.first[1]}</span>
                {text.first[2]}
                <br />
                {text.first[3]}
                <br />
                <br />
                {text.first[4]}
                <span style={{ color: "#0075bb" }}>{text.first[5]}</span>
                {text.first[6]}
                <ol>
                  <li>{text.first[7]}</li>
                  <li>{text.first[8]}</li>
                  <li>{text.first[9]}</li>
                  <li>{text.first[10]}</li>
                  <li>{text.first[11]}</li>
                </ol>
              </li>
              <li>{text.secend}</li>
              <li>
                {text.third[0]}
                <Link
                  to={`/upload${
                    languageType ? `?lang=${languageType}` : location.search
                  }`}
                  className={styles.link}
                >
                  {text.third[1]}
                </Link>
                {text.third[2]}
              </li>
              {/* <li>
                {text.download[0]}
                <a className={styles.link} onClick={download}>
                  {text.download[1]}
                </a>
                {text.download[2]}
              </li> */}
            </ol>
            <span>{text.end}</span>
          </Paragraph>
        )}
      </div>
  
  );
}
