import React, { useEffect, useState } from "react";
import styles from "./app.scss";
import GetRoutes from "./router";
import {
  BrowserRouter,
  useLocation,
  Link,
  useParams,
  useNavigate,
} from "react-router-dom";
import { message } from "antd";
import Head from "./components/head/head";
import { get, post } from "./request";
import Login from "./components/login";
import Router from "./router";
import md5 from "js-md5";
import { getSearch } from "./utils";

export default function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [currentTab, setCurrentTab] = useState("主页");
  const [loginOpen, setLoginOpen] = useState(false);
  const [languageType, setLanguageType] = useState("cn");
  const [tabs, setTabs] = useState([
    {
      id: 0,
      name: "主页",
      en: "Home",
    },
  ]);
  const changeVol = (newVol) => {
    setCurrentTab(newVol);
  };

  const onLogin = (values) => {
    console.log(values);
    post({
      url: "/login",
      data: {
        username: values.username,
        password: md5(values.password),
      },
      onSuccess: (data) => {
        console.log(data);
        const { user } = data;
        setUser(user);
        setLoginOpen(false);
        message.success("登录成功");
        window.location.reload();
      },
    });
  };
  useEffect(() => {
    setLoginOpen(!user);
  }, [user]);
  useEffect(() => {
    get({
      url: "/paper/categories",
      onSuccess: (data) => {
        const { categories } = data;
        let temp = [
          tabs[0],
          ...categories,
          {
            id: categories.length + 1,
            name: "上传的论文",
            en: "Uploaded Papers",
          },
        ];
        setTabs(temp);
      },
    });
    get({
      url: "/user/info",
      onSuccess: (data) => {
        setUser(data);
      },
      onFail: () => {
        setUser(null);
      },
    });
  }, []);

  // 刷新后根据pathname选中tab
  useEffect(() => {
    if (location.pathname.includes("evaluate")) {
      let id = location.pathname.substring(10);
      tabs.forEach((it) => {
        if (it.id == id) {
          setCurrentTab(it.name);
        }
      });
    } else {
      setCurrentTab(tabs[0].name);
    }
  });

  useEffect(() => {
    if (languageType) {
      if (location.search.indexOf('lang=') != -1) {
        navigate({
          pathname: location.pathname,
          search: location.search.replace(
            `lang=${languageType == "en" ? "cn" : "en"}`,
            `lang=${languageType}`
          ),
        });
      } else {
        navigate({
          pathname: location.pathname,
          search: `lang=${languageType}`,
        });
      }
    }
  }, [languageType]);

  // 根据url确定语言
  useEffect(()=>{
    console.log(getSearch(location.search));
    if (getSearch(location.search) && getSearch(location.search).lang && getSearch(location.search).lang != languageType) {
      setLanguageType(getSearch(location.search).lang)
    }
  },[])

  return (
    <React.Fragment>
      <Head
        user={user}
        changeVol={changeVol}
        onClickLogin={() => {
          !user && setLoginOpen(true);
        }}
        changeLanguage={() => {
          setLanguageType(languageType == "en" ? "cn" : "en");
        }}
      />
      <div className={styles.main}>
        <div
          className={styles.tabs}
          style={{ fontSize: languageType == "en" && 22 }}
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.id}
              tab={tab}
              active={tab.name === currentTab}
              onClick={() => {
                setCurrentTab(tab.name);
              }}
              changeVol={changeVol}
              languageType={languageType}
            />
          ))}
        </div>
        <Router languageType={languageType} user={user} />
      </div>
      <Login
        open={loginOpen}
        onCreate={onLogin}
        onCancel={() => {
          setLoginOpen(false);
        }}
      />
    </React.Fragment>
  );
}

function Tab({ tab, active, onClick, languageType }) {
  return (
    <Link
      to={tab.id == 0 ? `/home?lang=${languageType}` : `/evaluate/${tab.id}?lang=${languageType}`}
      className={`${styles.tab} ${active ? styles.active : ""}`}
      onClick={onClick}
    >
      <span>{languageType == "en" ? tab.en : tab.name}</span>
    </Link>
  );
}
