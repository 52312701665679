import React from "react";
import styles from "./head.scss";
import { ossServer } from "~/constant";
import { Dropdown } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { TranslationOutlined } from "@ant-design/icons";
import { goto, clearToken } from "~/utils";

export default function Head({ user, onClickLogin, changeLanguage }) {
  return (
    <div className={styles.head}>
      <div>
        {/* <img
          src={`${ossServer}/home/AIXIVLogo_greyword.png`}
          className={styles.logo}
        /> */}
        <h2>CUHK-Shenzhen test program</h2>
        <div className={styles.right}>
          <TranslationOutlined
            style={{ fontSize: 17 }}
            onClick={() => {
              changeLanguage();
            }}
          />
          <UserInfo user={user} onClickLogin={onClickLogin} />
        </div>
      </div>
    </div>
  );
}

function UserInfo({ user, onClickLogin }) {
  const logout = () => {
    console.log("----");
    clearToken();
    goto("/");
  };
  const items = [
    {
      key: "2",
      label: (
        <div onClick={logout}>
          <LogoutOutlined style={{ color: "#633869" }} />
          &nbsp;退出登录
        </div>
      ),
    },
  ];

  return (
    <Dropdown menu={{ items }} placement="bottom" disabled={!user}>
      <div className={styles.user} onClick={onClickLogin}>
        <img src={`${ossServer}/user.png`} alt="user" />
        {user?.username || "Login"}
      </div>
    </Dropdown>
  );
}
