import React, { useEffect, useState } from "react";
import moment from "moment";
import * as echarts from "echarts/core";
import { TitleComponent, TooltipComponent } from "echarts/components";
import { GraphChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
echarts.use([TitleComponent, TooltipComponent, GraphChart, CanvasRenderer]);
moment.locale("zh-cn");

// 穿透引用图
export default function PrChart({ info, checkedPaperItem, setDepthCorPapers }) {
  const [throughRefChart, setThroughRefChart] = useState(); // 穿透引用图数据
  useEffect(() => {
    if (info) {
      let similarDatas = info.similar_papers;
      let infoPapers = info.similar_papers;
      let currentYear = moment(info.metadata.pub_date).format("YYYY");
      let byYearStr = {}; // >=当前年份 按年份划分的所有深度相关论文
      let lessThanTwentyStr = {}; // byYearStr 20条以内的论文
      infoPapers.sort((a, b) => b.similarity - a.similarity);
      infoPapers.forEach((item, index) => {
        let itemYear = moment(item.metadata.pub_date).format("YYYY");
        if (itemYear <= currentYear) {
          // All
          if (byYearStr.hasOwnProperty(itemYear)) {
            byYearStr[itemYear].push(item);
          } else {
            byYearStr[itemYear] = [{ ...item }];
          }
        }
      });
      lessThanTwentyStr = { ...byYearStr };
      Object.keys(lessThanTwentyStr).map((key) => {
        if (lessThanTwentyStr[key].length > 3) {
          lessThanTwentyStr[key].length = 3;
        }
      });
      setThroughRefChart(lessThanTwentyStr);
      similarDatas.forEach((item, index) => {
        Object.keys(lessThanTwentyStr).map((key) => {
          lessThanTwentyStr[key].map((it) => {
            if (it.metadata.doi == item.metadata.doi) {
              similarDatas.splice(index, 1);
            }
          });
        });
      });
      setDepthCorPapers([...similarDatas]);
    }
  }, [info]);
  useEffect(() => {
    if (info && throughRefChart) {
      var myDate = new Date();
      var tYear = myDate.getFullYear();
      let infoYear = moment(info.metadata.pub_date).format("YYYY");
      var chartDom = document.getElementById("bite");
      var myChart = echarts.init(chartDom);
      var option;
      // 截取本文前六年
      let penetrationData = { ...throughRefChart };
      let tmp1 = Object.entries(penetrationData).slice(
        -6,
        penetrationData.length
      );
      penetrationData = Object.fromEntries(tmp1);

      let axisData = Object.keys(penetrationData);
      let mainData = [];
      let data = []; // 主论文
      let penetrationData1 = { ...penetrationData }; // 删去主论文
      let seriesConfig = []; // config echarts数据
      let checkDoi = info.metadata.doi; // 选中的文章doi，默认本文
      let allDatas = []; // 所有数据
      //#region 主论文
      axisData.map((key, i) => {
        let max = penetrationData[key][0];
        if (max?.similarity > 0.5) {
          mainData.push(max); // 收集每年的主论文
          penetrationData1[key].splice(0, 1); // 删除每年的主论文
        } else {
          mainData.push(undefined);
        }
      });
      let count = []; // 主论文数组中存在数据的索引集合
      axisData.push("本文");
      if (mainData.length != 0) {
        mainData.push({
          metadata: info.metadata,
          summary: info.summary,
          similarity: 0.65,
        });
        data = mainData.map(function (item, i) {
          if (item != undefined) {
            let time = moment(item.metadata.pub_date).format("YYYY");
            count.push(i);
            return {
              id: Math.random(),
              value: item.similarity,
              symbolSize: i == 6 ? 70 : 70 - i + item.similarity,
              axisName: i == 6 ? "本文" : time,
              time: time,
              datas: item,
              x: (i + 1) * 80,
              y: item.similarity * 2 + 400,
            };
          } else {
            return {};
          }
        });
      }
      const links = data.map(function (item, i) {
        let site = count.indexOf(i);
        if (site != -1 && site != 0) {
          // 存在i 不是第一个
          return {
            source: i,
            target: count[site - 1],
            lineStyle: {
              opacity: 0.9,
              width: 2,
              curveness: 0,
              color: "#2f4554",
            },
          };
        } else {
          return {
            source: i,
            target: i - 1,
            lineStyle: {
              opacity: 0.9,
              width: 2,
              curveness: 0,
              color: "#2f4554",
            },
          };
        }
      });
      //#endregion
      // 其他（主论文之外的论文）
      let axisData1 = Object.keys(penetrationData1);
      let maxLength = Object.values(penetrationData1).sort(
        (a, b) => b.length - a.length
      )[0].length;
      let tempStr = {};
      // 补充undefined
      axisData1.map(function (key, i) {
        let arr = [];
        arr = penetrationData1[key];
        for (let i = 0; i < maxLength - penetrationData1[key].length; i++) {
          arr = [...arr, undefined];
        }
        arr.length != 0 && (tempStr[key] = arr);
      });
      // 按照一组六个年份来划分数组对象
      if (tempStr != {}) {
        function getData(obj) {
          let keys = Object.keys(obj);
          let ss = {};
          for (let i = 0; i < obj[keys[0]].length; i++) {
            ss[i] = [];
          }
          keys.map(function (key, i) {
            for (let i = 0; i < obj[key].length; i++) {
              ss[i].push(obj[key][i]);
            }
          });
          return ss;
        }
        let arrResult = getData(tempStr);
        let flag = 1;
        Object.keys(arrResult).map(function (k, i) {
          let itemData = arrResult[k];
          let count = i;
          itemData.map(function (item, idx) {
            if (item != undefined) {
              let time = moment(item.metadata.pub_date).format("YYYY");
              data.push({
                id: Math.random(),
                value: item.similarity - (count + 1) / 10,
                symbolSize: 60 - flag + item.similarity,
                name: `${time}${item.similarity}`,
                time: time,
                datas: item,
                x: (idx + 1) * 80,
                y: count == 0 ? 300 : count == 1 ? 100 : 500,
              });
              flag++;
            } else {
              data.push({});
            }
            switch (count) {
              case 0:
                links.push({
                  source: idx,
                  target: 7 + idx,
                  lineStyle: {
                    type: "dashed",
                  },
                });
                break;
              case 1:
                links.push({
                  source: 7 + idx,
                  target: 13 + idx,
                  lineStyle: {
                    type: "dashed",
                  },
                });
                break;
              case 2:
                links.push({
                  source: mainData[idx] == undefined ? 7 + idx : idx,
                  target: 19 + idx,
                  lineStyle: {
                    type: "dashed",
                  },
                });
                break;

              default:
                break;
            }
          });
        });
      }
      if (data.length != 0) {
        option = {
          tooltip: {},
          animationDurationUpdate: 1500,
          animationEasingUpdate: "quinticInOut",
          series: {
            type: "graph",
            layout: "none",
            roam: true,
            edgeSymbol: ["circle", "arrow"],
            edgeSymbolSize: [4, 10],
            edgeLabel: {
              fontSize: 20,
            },
            data: data,
            links: links,
            label: {
              show: true,
              formatter: (params) => {
                return `${
                  params.dataIndex == 6
                    ? "本文"
                    : params.data.datas.similarity.toFixed(2)
                },\n${params.data.time}`;
              },
            },
            itemStyle: {
              color: function (params) {
                if (params.dataIndex <= 6) {
                  if (
                    params.data.datas &&
                    checkDoi == params.data.datas.metadata.doi
                  ) {
                    return "#ff2dff"; // check
                  } else {
                    return "#5470c6";
                  }
                } else if (params.dataIndex > 6) {
                  if (
                    params.data.datas &&
                    checkDoi == params.data.datas.metadata.doi
                  ) {
                    return "#ff2dff"; // check
                  } else {
                    return "#91cc75";
                  }
                }
              },
            },
          },
        };
      }
      myChart.on("click", { dataType: "node" }, function (params) {
        // 关系图的节点被点击时此方法被回调。
        checkDoi = params.data.datas.metadata.doi;
        myChart.setOption(option);
        params.name == "本文"
          ? checkedPaperItem(info)
          : checkedPaperItem(params.data.datas);
      });
      option && myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    }
  }, [info, throughRefChart]);
  return (
    <div style={{ width: 600, margin: '0 auto' }}>
      <div id="bite" style={{ height: 600, margin: "0 auto" }}></div>
    </div>
  );
}
