import html2canvas from "html2canvas";
import jsPDF from "jspdf";

/**
 * 导出PDF
 * @param {导出后的文件名} filename
 * @param {要导出的dom节点} dom
 * @param {导出的文件水印：用户邮箱} email
 */

export const exportPDF = (filename, dom, email) => {
  const scale = 1;

  // 滚动到顶部，避免打印不全
  document.documentElement.scrollTop = 0;

  html2canvas(dom, {
    allowTaint: true, // Whether to allow cross-origin images to taint the canvas
    scale, // The scale to use for rendering. Defaults to the browsers device pixel ratio.
  }).then((canvas) => {
    const contentWidth = canvas.width / scale;
    const contentHeight = canvas.height / scale;
    console.log(
      "height",
      contentHeight,
      canvas.height,
      contentWidth,
      canvas.width,
      dom.offsetWidth,
      dom.offsetHeight,
    );
    const pdf = new jsPDF("", "pt", [contentWidth, contentHeight]);
    const pageData = canvas.toDataURL("image/jpeg", 1.0);

    pdf.addImage(
      pageData,
      "JPEG",
      (contentWidth - contentWidth / 1.5) / 2, // x偏移
      20, // y偏移
      contentWidth / 1.5,
      contentHeight > 14400 ? 14380 : contentHeight,
      "",
      "FAST"
    );

    // 添加水印
    for (let i = 1; i < contentHeight / 240 - 1; i++) {
      pdf.setTextColor(150);
      pdf.setFontSize(35);
      pdf.setFont("courier");
      pdf.text(contentWidth / 2, 450 * i, email, 45);
    }

    pdf.save(`${filename}.pdf`);
  });
};
