export const journals = {
  Nature: [
    { name: "Nature Biotechnology", url: "https://www.nature.com/nbt/" },
    { name: "Scientific Reports", url: "https://www.nature.com/srep/" },
    { name: "Oncogene", url: "https://www.nature.com/onc/" },
    { name: "Neuropsychopharmacology", url: "https://www.nature.com/npp/" },
    { name: "Nature Physics", url: "https://www.nature.com/nphys/" },
    { name: "Nature Climate Change", url: "https://www.nature.com/nclimate/" },
    { name: "Communications biology", url: "https://www.nature.com/commsbio/" },
    { name: "Nature Nanotechnology", url: "https://www.nature.com/nnano/" },
    { name: "Nature Chemistry", url: "https://www.nature.com/nchem/" },
    { name: "Nature Materials", url: "https://www.nature.com/nmat/" },
    { name: "Nature Immunology", url: "https://www.nature.com/ni/" },
    { name: "Nature Microbiology", url: "https://www.nature.com/nmicrobiol/" },
    { name: "Nature Biotechnology", url: "https://www.nature.com/nbt/" },
    { name: "Nature Catalysis", url: "https://www.nature.com/natcatal/" },
    { name: "Nature Protocols", url: "https://www.nature.com/nprot/" },
    {
      name: "Nature Structural & Molecular Biology",
      url: "https://www.nature.com/nsmb/",
    },
    { name: "NPG Asia Materials", url: "https://www.nature.com/am/" },
    {
      name: "Nature Human Behaviour",
      url: "https://www.nature.com/nathumbehav/",
    },
    { name: "Nature Plants", url: "https://www.nature.com/nplants/" },
    { name: "Nature Photonics", url: "https://www.nature.com/nphoton/" },
    { name: "Nature Neuroscience", url: "https://www.nature.com/neuro/" },
  ],
  Elsevier: [
    {
      name: "Brain Research",
      url: "https://www.sciencedirect.com/journal/brain-research",
    },
    {
      name: "Journal of Biological Chemistry",
      url: "https://www.sciencedirect.com/journal/journal-of-biological-chemistry",
    },
    {
      name: "Biochemical and Biophysical Research Communications",
      url: "https://www.sciencedirect.com/journal/biochemical-and-biophysical-research-communications",
    },
    { name: "Polymer", url: "https://www.sciencedirect.com/journal/polymer" },
    {
      name: "Water Research",
      url: "https://www.sciencedirect.com/journal/water-research",
    },
    {
      name: "Journal of Catalysis",
      url: "https://www.sciencedirect.com/journal/journal-of-catalysis",
    },
    {
      name: "Biomaterials",
      url: "https://www.sciencedirect.com/journal/biomaterials",
    },
    {
      name: "Electrochimica Acta",
      url: "https://www.sciencedirect.com/journal/electrochimica-acta",
    },
    {
      name: "Journal of Hazardous Materials",
      url: "https://www.sciencedirect.com/journal/journal-of-hazardous-materials",
    },
    {
      name: "Journal of Chromatography A",
      url: "https://www.sciencedirect.com/journal/journal-of-chromatography-a",
    },
    { name: "Virology", url: "https://www.sciencedirect.com/journal/virology" },
    {
      name: "Chemical Physics Letters",
      url: "https://www.sciencedirect.com/journal/chemical-physics-letters",
    },
  ],
  "American Chemical Society": [
    {
      name: "Journal of Physical Chemistry B",
      url: "https://pubs.acs.org/journal/jpcbfk",
    },
    {
      name: "Journal of Chemical Information and Modeling",
      url: "http://pubs.acs.org/journals/jcisd8/index.html",
    },
    { name: "Macromolecules", url: "http://pubs.acs.org/journal/mamobx" },
    { name: "Nano Letters", url: "http://pubs.acs.org/journal/nalefd" },
    {
      name: "Accounts of Chemical Research",
      url: "http://pubs.acs.org/journal/achre4",
    },
    {
      name: "ACS Applied Materials & Interfaces",
      url: "http://pubs.acs.org/journal/aamick",
    },
    { name: "ACS energy letters", url: "http://pubs.acs.org/journal/aelccp" },
    {
      name: "Organometallics",
      url: "http://pubs.acs.org/journals/orgnd7/index.html",
    },
    {
      name: "Organic Letters",
      url: "http://pubs.acs.org/journals/orlef7/index.html",
    },
    {
      name: "Bioconjugate Chemistry",
      url: "http://pubs.acs.org/journal/bcches",
    },
    {
      name: "Journal of Chemical Theory and Computation",
      url: "http://pubs.acs.org/journal/jctcce",
    },
    { name: "Langmuir", url: "http://pubs.acs.org/journal/langd5/" },
    { name: "ACS Macro Letters", url: "http://pubs.acs.org/journal/amlccd" },
    {
      name: "Journal of Agricultural and Food Chemistry",
      url: "http://pubs.acs.org/journal/jafcau",
    },
    {
      name: "Journal of Physical Chemistry B",
      url: "http://pubs.acs.org/journals/jpcbfk/index.html",
    },
    {
      name: "Environmental Science & Technology",
      url: "http://pubs.acs.org/journals/esthag/",
    },
    {
      name: "ACS Sustainable Chemistry & Engineering",
      url: "http://pubs.acs.org/journal/ascecg",
    },
    { name: "ACS Photonics", url: "http://pubs.acs.org/journal/apchd5" },
  ],
  "Royal Society of Chemistry": [
    {
      name: "Nanoscale",
      url: "https://www.rsc.org/journals-books-databases/about-journals/nanoscale/",
    },
    {
      name: "Chemical Communications",
      url: "https://www.rsc.org/journals-books-databases/about-journals/chemcomm/",
    },
    {
      name: "Journal of materials chemistry A",
      url: "https://pubs.rsc.org/en/journals/journalissues/ta",
    },
    {
      name: "Journal of Materials Chemistry B",
      url: "https://www.rsc.org/journals-books-databases/about-journals/journal-of-materials-chemistry-b/",
    },
    {
      name: "Dalton Transactions",
      url: "https://pubs.rsc.org/en/journals/journalissues/dt",
    },
    {
      name: "Food & Function",
      url: "https://www.rsc.org/journals-books-databases/about-journals/food-function/",
    },
    {
      name: "Nanoscale",
      url: "https://www.rsc.org/journals-books-databases/about-journals/nanoscale/",
    },
    {
      name: "Analytical Methods",
      url: "https://www.rsc.org/journals-books-databases/about-journals/analytical-methods/",
    },
  ],
  "Multidisciplinary Digital Publishing Institute（MDPI）": [
    { name: "Genes", url: "https://www.mdpi.com/journal/genes" },
    { name: "Viruses", url: "https://www.mdpi.com/journal/Viruses" },
    { name: "Biomedicines", url: "https://www.mdpi.com/journal/Biomedicines" },
    {
      name: "Pharmaceutics",
      url: "https://www.mdpi.com/journal/Pharmaceutics",
    },
    {
      name: "International Journal of Environmental Research and Public Health",
      url: "https://www.mdpi.com/journal/ijerph",
    },
    { name: "Energies", url: "https://www.mdpi.com/journal/energies" },
    { name: "Agronomy", url: "https://www.mdpi.com/journal/Agronomy" },
    { name: "Molecules", url: "https://www.mdpi.com/journal/Molecules" },
    { name: "Polymers", url: "https://www.mdpi.com/journal/Polymers" },
    { name: "Symmetry", url: "https://www.mdpi.com/journal/Symmetry" },
    { name: "Mathematics", url: "https://www.mdpi.com/journal/Mathematics" },
    {
      name: "Micromachines",
      url: "https://www.mdpi.com/journal/Micromachines",
    },
    { name: "Metals", url: "https://www.mdpi.com/journal/Metals" },
    { name: "Atmosphere", url: "https://www.mdpi.com/journal/Atmosphere" },
    { name: "Electronics", url: "https://www.mdpi.com/journal/Electronics" },
    { name: "Antioxidants", url: "https://www.mdpi.com/journal/Antioxidants" },
    { name: "Crystals", url: "https://www.mdpi.com/journal/Crystals" },

    { name: "Minerals", url: "https://www.mdpi.com/journal/Minerals" },
    { name: "Sensors", url: "https://www.mdpi.com/journal/Sensors" },
    { name: "Catalysts", url: "https://www.mdpi.com/journal/Catalysts" },
    { name: "Processes", url: "https://www.mdpi.com/journal/Processes" },
    { name: "Antibiotics", url: "https://www.mdpi.com/journal/Antibiotics" },
    {
      name: "Medicina-lithuania",
      url: "https://www.mdpi.com/journal/medicina",
    },
    { name: "Coatings", url: "https://www.mdpi.com/journal/Coatings" },
    {
      name: "Microorganisms",
      url: "https://www.mdpi.com/journal/Microorganisms",
    },
    { name: "Toxins", url: "https://www.mdpi.com/journal/Toxins" },
    {
      name: "Nanomaterials",
      url: "https://www.mdpi.com/journal/Nanomaterials",
    },
    { name: "Forests", url: "https://www.mdpi.com/journal/Forests" },
    { name: "Plants", url: "https://www.mdpi.com/journal/Plants" },
    {
      name: "ISPRS international journal of geo-information",
      url: "https://www.mdpi.com/journal/ijgi",
    },
    { name: "Cells", url: "https://www.mdpi.com/journal/Cells" },
    { name: "Applied sciences", url: "https://www.mdpi.com/journal/applsci" },
    {
      name: "International Journal of Molecular Sciences",
      url: "https://www.mdpi.com/journal/ijms",
    },
  ],
  "Taylor & Francis": [
    {
      name: "International Journal of Production Research",
      url: "https://www.tandfonline.com/journals/tprs20",
    },
    {
      name: "Journal of Sports Sciences",
      url: "https://www.tandfonline.com/journals/rjsp20",
    },
    {
      name: "Journal of Plant Nutrition",
      url: "https://www.tandfonline.com/journals/lpla20",
    },
    {
      name: "Multivariate Behavioral Research",
      url: "https://www.tandfonline.com/journals/hmbr20",
    },
    {
      name: "Cognition & Emotion",
      url: "https://www.tandfonline.com/journals/pcem20",
    },
    {
      name: "The Journal of Social Psychology",
      url: "https://www.tandfonline.com/journals/vsoc20",
    },
    {
      name: "Separation Science and Technology",
      url: "https://www.tandfonline.com/journals/lsst20",
    },
    {
      name: "Journal of Toxicology and Environmental Health, Part A",
      url: "https://www.tandfonline.com/journals/uteh20",
    },
    {
      name: "The Journal of Higher Education",
      url: "https://www.tandfonline.com/journals/uhej20",
    },
    {
      name: "Mycologia",
      url: "https://www.tandfonline.com/journals/umyc20",
    },
    {
      name: "Critical Reviews in Food Science and Nutrition",
      url: "https://www.tandfonline.com/journals/bfsn20",
    },
    {
      name: "Communications in Soil Science and Plant Analysis",
      url: "https://www.tandfonline.com/journals/lcss20",
    },
    {
      name: "Cell Cycle",
      url: "https://www.tandfonline.com/journals/kccy20",
    },
    {
      name: "Advances in Physics",
      url: "https://www.tandfonline.com/journals/tadp20",
    },
    {
      name: "Synthetic Communications",
      url: "https://www.tandfonline.com/journals/lsyc20",
    },
    {
      name: "International Journal of Control",
      url: "https://www.tandfonline.com/journals/tcon20",
    },
    {
      name: "Journal of Personality Assessment",
      url: "https://www.tandfonline.com/journals/hjpa20",
    },
    {
      name: "International Journal of Remote Sensing",
      url: "https://www.tandfonline.com/journals/tres20",
    },
    {
      name: "Aerosol Science and Technology",
      url: "https://www.tandfonline.com/journals/uast20",
    },
    {
      name: "Journal of the American Statistical Association",
      url: "https://www.tandfonline.com/journals/uasa20",
    },
    {
      name: "Free Radical Research",
      url: "https://www.tandfonline.com/journals/ifra20",
    },
    {
      name: "Applied Economics",
      url: "http://www.tandfonline.com/toc/raec20/current",
    },
    {
      name: "Molecular Physics",
      url: "https://www.tandfonline.com/toc/tmph20/current",
    },
    {
      name: "Journal of Business & Economic Statistics",
      url: "http://amstat.tandfonline.com/loi/jbes#.uvek9_zmsnq",
    },
    {
      name: "Structural Equation Modeling: A Multidisciplinary Journal",
      url: "https://www.tandfonline.com/toc/hsem20/current",
    },
    { name: "Technometrics", url: "http://amstat.tandfonline.com/loi/tech" },
    {
      name: "Ergonomics",
      url: "https://www.tandfonline.com/toc/terg20/current",
    },
    {
      name: "Ferroelectrics",
      url: "https://www.tandfonline.com/toc/gfer20/current",
    },
    {
      name: "Autophagy",
      url: "http://www.landesbioscience.com/journals/autophagy/",
    },
    {
      name: "International Journal of Science Education",
      url: "https://www.tandfonline.com/toc/tsed20/current",
    },
  ],
  "American Institute of Physics": [
    {
      name: "Applied physics reviews",
      url: "http://scitation.aip.org/dbt/dbt.jsp?KEY=JAPAPR",
    },
    { name: "Biomicrofluidics", url: "https://pubs.aip.org/aip/bmf" },
    { name: "Biophysics reviews", url: "https://pubs.aip.org/aip/bpr" },
    { name: "Journal of Chemical Physics", url: "http://jcp.aip.org/" },
    { name: "APL Materials", url: "http://aplmaterials.aip.org/" },
    { name: "AIP Advances", url: "https://pubs.aip.org/aip/adv" },
    { name: "Applied Physics Letters", url: "http://apl.aip.org/" },
    {
      name: "Journal of Renewable and Sustainable Energy",
      url: "http://jrse.aip.org/",
    },
    { name: "Review of Scientific Instruments", url: "http://rsi.aip.org/" },
    { name: "Physics of Fluids", url: "http://pof.aip.org/" },
    { name: "Physics of Plasmas", url: "http://pop.aip.org/" },
  ],
  Wiley: [
    {
      name: "Chemistry - A European Journal",
      url: "http://www.wiley-vch.de/publish/en/journals/alphabeticIndex/2111/",
    },
    {
      name: "European Journal of Organic Chemistry",
      url: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291099-0690",
    },
    {
      name: "Journal of Applied Polymer Science",
      url: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291097-4628",
    },
    {
      name: "Medical Physics",
      url: "https://aapm.onlinelibrary.wiley.com/journal/24734209",
    },
    {
      name: "Annals of Neurology",
      url: "http://www3.interscience.wiley.com/journal/76507645/home",
    },
    {
      name: "Angewandte Chemie",
      url: "https://onlinelibrary.wiley.com/journal/14791838",
    },
    {
      name: "Biotechnology and Bioengineering",
      url: "https://onlinelibrary.wiley.com/journal/10970290",
    },
    {
      name: "Electrophoresis",
      url: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1522-2683",
    },
    {
      name: "Angewandte Chemie International Edition",
      url: "https://onlinelibrary.wiley.com/journal/15213773",
    },
    {
      name: "Advanced Synthesis & Catalysis",
      url: "https://onlinelibrary.wiley.com/journal/16154169",
    },
    {
      name: "Human Brain Mapping",
      url: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1097-0193",
    },
    {
      name: "Journal of the Science of Food and Agriculture",
      url: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1097-0010",
    },
    {
      name: "Otolaryngology-Head and Neck Surgery",
      url: "https://aao-hnsfjournals.onlinelibrary.wiley.com/journal/10976817",
    },
  ],
  "SAGE Publishing": [
    {
      name: "Journal of Children's Orthopaedics",
      url: "https://journals.sagepub.com/home/CHO",
    },
    {
      name: "Innate Immunity",
      url: "http://www.sagepub.com/journals/Journal201861/title",
    },
    {
      name: "Journal of International Medical Research",
      url: "https://us.sagepub.com/en-us/nam/journal/journal-international-medical-research",
    },
    { name: "Pulmonary circulation", url: "https://pvri.info/en/pc-journal" },
    {
      name: "Therapeutic Advances in Respiratory Disease",
      url: "https://us.sagepub.com/en-us/nam/journal/therapeutic-advances-respiratory-disease",
    },
  ],
};
