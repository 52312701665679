import React, { useEffect, useState } from "react";
import { Input, Popover, Space, message, Button } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { post, get } from "~/request";
import styles from "../index.scss";
import TableStatus from "./tableStatus";
import { journals } from "../data.js";

export default function DoiUpload({ categories, languageType }) {
  const [list, setList] = useState([]);
  const [notifyEmail, setNotifyEmail] = useState(null);
  const [doi, setDoi] = useState(null);
  const [cid, setCid] = useState();
  const [cidOptions, setCidOptions] = useState([]);

  const getList = () => {
    // 获取分析任务列表
    let resultArr = [];
    get({
      url: "/paper/analysis/list",
      onSuccess: (res) => {
        // 按照doi顺序排列
        res.sort(function (a, b) {
          var x = a.doi;
          var y = b.doi;
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });
        // 删除相同doi且时间戳小的
        for (let index = 0; index < res.length; index++) {
          let now = res[index];
          let prev = res[index - 1];
          if (index == 0) {
            resultArr.push(now);
          } else if (index > 0) {
            if (prev.doi != now.doi) {
              resultArr.push(now);
            } else {
              if (prev.createdAt < now.createdAt) {
                resultArr[resultArr.length - 1] = now;
              }
            }
          }
        }
        // 按时间排序
        resultArr.sort((a, b) => {
          return b.createdAt - a.createdAt;
        });
        setList([...resultArr]);
      },
    });
  };

  // 解析
  const analyze = () => {
    var reg = /^(10[.][0-9]{4,}(?:[.][0-9]+)*\/(?:(?!["&\'<>])\S)+)$/;
    if (reg.test(doi)) {
      setDoi(null);
      // 创建分析任务
      post({
        url: "/paper/analysis/batch",
        data: [
          {
            // cid: cid ? cid : 0,
            cid: 0,
            notifyEmail,
            doi,
          },
        ],
        onSuccess: (data) => {
          console.log(data);
          message.success(
            languageType == "cn"
              ? "已开始为您解析..."
              : "Parsing has begun for you..."
          );
          getList();
        },
      });
    } else {
      message.info(
        languageType == "cn"
          ? "请检查输入的DOI是否正确"
          : "Please check that the DOI entered is correct"
      );
    }
  };

  useEffect(() => {
    getList();
    const handler = setInterval(() => {
      getList();
    }, 60 * 1000);
    return () => {
      clearInterval(handler);
    };
  }, []);

  useEffect(() => {
    if (categories.length != 0) {
      let arr = [];
      categories.map((it) => {
        arr.push({
          key: it.id,
          value: it.name,
          label: it.name,
        });
      });
      console.log(arr);
      arr.length != 0 && setCidOptions([...arr]);
    }
  }, [categories]);

  const emailChange = (e) => {
    setNotifyEmail(e.target.value);
  };

  const doiChange = (e) => {
    setDoi(e.target.value);
  };
  const getCid = (value, options) => {
    setCid(options.key);
  };

  const content = (
    <div>
      {Object.keys(journals).map((key) => {
        return (
          <div key={key}>
            <h4>{key}</h4>
            {journals[key].map((item, index) => {
              return (
                <div key={index} className={styles.journalTxt}>
                  <a href={item.url}>
                    {item.name}
                    {index != journals[key].length - 1 && ", "}
                  </a>
                  &nbsp;
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );

  return (
    <div className={styles.doiUpload}>
      {/* <div>
          <label>请选择文章所属学科（可选）：</label>
          <Select options={cidOptions} onChange={getCid} />
        </div> */}
      <p>
        {languageType == "cn"
          ? "请输入进行分析的文章DOI"
          : "Please enter the DOI of the article for analysis"}{" "}
        <span>
          （
          {languageType == "cn"
            ? "目前仅支持部分期刊，我们正在开发以支持更多期刊。例如10.1021/acs.jpclett.3c01668，请勿输入“https”、“doi.org”等字符"
            : "Currently, only some journals are supported, and we are working on supporting more journals. For example, 10.1021/acs.jpclett.3c01668. Please do not input characters like 'https' or 'doi.org'."}
          ）
        </span>
        ：
      </p>
      <div className={styles.bot}>
        <Input
          value={doi}
          placeholder={
            languageType == "cn"
              ? "输入论文DOI，如：10.1021/acs.jpclett.3c01668"
              : "DOI input paper, such as: 10.1021/acs.jpclett.3c01668"
          }
          size="large"
          onChange={doiChange}
        />
        <Input
          placeholder={
            languageType == "cn"
              ? "（可选）输入您的邮箱地址，解析成功后会通知您。"
              : "(Optional) Enter your e-mail so that we can notify you after the analysis is completed. "
          }
          onChange={emailChange}
          size="large"
        />
        <Button disabled={!doi} onClick={analyze} size="large">
          {languageType == "cn" ? "解析" : "Analyze"}
        </Button>
      </div>
      <Popover
        content={content}
        placement="bottom"
        overlayStyle={{
          maxWidth: "calc(100vw - 100px)",
          maxHeight: 500,
          overflowY: "scroll",
        }}
      >
        <Space>
          {languageType == "cn"
            ? "目前支持的期刊列表"
            : "List of currently supported journals"}
          <RightOutlined />
        </Space>
      </Popover>
      {list.length > 0 && (
        <TableStatus
          data={list}
          categories={categories}
          type="doi"
          languageType={languageType}
        />
      )}
    </div>
  );
}
