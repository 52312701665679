import React, { useEffect, useState } from "react";
import { Button, Form, message, Input, Upload } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import TableStatus from "./tableStatus";
import { get, post, request } from "~/request";
import styles from "../index.scss";
const { Dragger } = Upload;

export default function PdfUpload({ categories, languageType }) {
  const [list, setList] = useState([]);
  const [uploadFinished, setUploadFinished] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [notifyEmail, setNotifyEmail] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [status, setStatus] = useState();

  const getList = () => {
    // 获取分析任务列表
    get({
      url: "/paper/analysis/list",
      onSuccess: (res) => {
        res.sort((a, b) => {
          return b.createdAt - a.createdAt;
        });
        res.forEach((item) => {
          item["key"] = item.id;
        });
        setList([...res]);
      },
    });
  };

  // 解析
  const analyze = () => {
    // 创建分析任务
    if (fileList.length > 0) {
      let tempList = fileList.filter((file) => file.status == "done");
      if (tempList.length == fileList.length) {
        post({
          url: "/paper/analysis/batch",
          data: fileList.map((file) => {
            return {
              cid: 0,
              notifyEmail,
              fileName: file.name,
              link: file.url,
            };
          }),
          onSuccess: (data) => {
            console.log(data);
            message.success(
              languageType == "cn"
                ? "已开始为您解析..."
                : "Parsing has begun for you..."
            );
            setFileList([]);
            getList();
          },
        });
      } else {
        message.warning(
          languageType == "cn"
            ? "请等待PDF上传完成"
            : "Please wait for PDF upload to complete"
        );
      }
    }
  };

  useEffect(() => {
    getList();
    const handler = setInterval(() => {
      getList();
    }, 60 * 1000);
    return () => {
      clearInterval(handler);
    };
  }, []);

  useEffect(() => {
    if (fileList.length > 0) {
      setUploadFinished(true);
    } else {
      setUploadFinished(false);
    }
  }, [fileList]);

  useEffect(() => {
    console.log("----status-----", status);
  }, [status]);

  const emailChange = (e) => {
    setNotifyEmail(e.target.value);
  };

  return (
    <div className={styles.manuscript}>
      {contextHolder}
      <p>
        {languageType == "cn"
          ? "请上传进行分析的文章PDF"
          : "Please upload the article PDF for analysis"}{" "}
        <span>
          （
          {languageType == "cn"
            ? "10MB以内，不支持扫描文档；若PDF无法解析，请提供文章DOI"
            : "up to 10MB in size, scanned documents are not supported"}
          ）
        </span>
        ：
      </p>
      <Form>
        <Form.Item name="link">
          <AliyunOSSUpload
            fileList={fileList}
            setFileList={setFileList}
            setStatus={setStatus}
            languageType={languageType}
          />
        </Form.Item>
      </Form>

      <div className={styles.bot}>
        <Button disabled={!uploadFinished} onClick={analyze} size="large">
          {languageType == "cn" ? "解析" : "Analyze"}
        </Button>
        <Input
          placeholder={
            languageType == "cn"
              ? "（可选）请输入您的邮箱地址，当所有论文完成解析时我们将通知您。"
              : "(Optional) Enter your e-mail so that we can notify you after the analysis is completed."
          }
          onChange={emailChange}
          size="large"
        />
      </div>
      <p>
        <span>
          {languageType == "cn"
            ? "仅支持解析英文论文PDF（由于不同期刊的PDF格式各异，可能出现不支持的情况）"
            : "Only support parsing English paper PDF(Due to the different PDF format of different journals, it may not be supported)"}
        </span>
      </p>
      {list.length > 0 && (
        <TableStatus
          data={list}
          categories={categories}
          type="upload"
          languageType={languageType}
        />
      )}
    </div>
  );
}

const AliyunOSSUpload = ({
  fileList,
  setFileList,
  setStatus,
  languageType,
}) => {
  const uploadFile = async (options) => {
    const { file, onSuccess, onProgress, onError } = options;

    request({
      url: "/oss/upload",
      data: {
        file: file,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
      method: "post",
      onUploadProgress: (progress) => {
        file.percent = (progress.loaded / progress.total) * 100;
        onProgress(
          {
            percent: Math.round(
              (progress.loaded / progress.total) * 100
            ).toFixed(2),
          },
          file
        );
      },
      onSuccess: (res) => {
        console.log(`${file.name} upload succeed`);
        console.log(res, file, fileList);
        fileList.map((item) => {
          if (item.name == file.name) {
            file.url = res;
          }
        });
        console.log(fileList);
        onSuccess({ ...res, name: file.name, status: "done" }, file);
      },
      onFail: () => {
        onError();
        console.log(`${file.name} upload failed`);
      },
    });

    return {
      abort() {
        console.log("上传进度中止");
      },
    };
  };
  const props = {
    name: "file",
    accept: ".pdf",
    multiple: true,
    fileList,
    onChange(info) {
      const { status } = info.file;
      console.log(info);
      if (status === "done") {
        console.log("----success----", status);
        setStatus(status);
        message.success(languageType == "cn" ? "上传成功" : "Success");
      } else if (status === "error") {
        message.error(languageType == "cn" ? `上传失败` : "Error");
      } else if (info.file.type !== "application/pdf") {
        message.error("仅支持.pdf格式附件!");
      }
      setFileList(
        info.fileList.filter((file) => file.type === "application/pdf")
      );
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
    customRequest: uploadFile,
  };
  return (
    <Dragger {...props}>
      <div>
        <CloudUploadOutlined />
        <div>
          <p>Drag and drop file here</p>
          <p>Limit 10MB per file • PDF</p>
        </div>
      </div>
      <Button size="large">Browse files</Button>
    </Dragger>
  );
};
