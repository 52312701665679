import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Divider, Modal, Radio, Spin, Table, message } from "antd";
import {
  LoadingOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { get } from "~/request";
import styles from "../index.scss";
import PLimit from "p-limit";
moment.locale("zh-cn");

export default function TableStatus({ data, categories, type, languageType }) {
  const navigate = useNavigate();
  const [columns, setColumns] = useState();
  const [statusPrompt, setStatusPrompt] = useState();
  const [selectionType, setSelectionType] = useState(-1);
  const [classifyData, setClassifyData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState();
  const [selectedRowsAll, setSelectedRowsAll] = useState([]);
  const [jsonData, setJsonData] = useState();
  const [loading, setLoading] = useState(false);
  const [isDownLoad, setIsDownLoad] = useState(true); // 是否可以展示全部已完成数据
  const analysisStatus = (file) => {
    if (
      file.createdAt < moment().valueOf() - 2 * 3600 * 1000 &&
      file.status === 0
    ) {
      return "暂时无法解析，正在改进中";
    }
    return (
      <>
        {statusPrompt[file.status].icon} {statusPrompt[file.status].txt}
      </>
    );
  };

  useEffect(() => {
    if (data.length != 0) {
      let temp1 = [];
      let temp2 = [];
      let temp3 = [];

      data.forEach((item) => {
        item["key"] = item.id;
        switch (item.status) {
          case 0:
            temp1.push(item);
            break;
          case 10:
            temp2.push(item);
            break;
          case 20:
            temp3.push(item);
            break;
          default:
            break;
        }
      });
      setClassifyData({
        0: temp1,
        10: temp2,
        20: temp3,
      });
    }
  }, [data]);

  useEffect(() => {
    setStatusPrompt({
      0: {
        icon: <LoadingOutlined />,
        txt: languageType == "cn" ? "进行中" : "Under way",
      },
      10: {
        icon: <CheckOutlined />,
        txt: languageType == "cn" ? "已完成" : "Completed",
      },
      20: {
        icon: <CloseOutlined />,
        txt:
          languageType == "cn"
            ? "目前暂不支持该文章，我们正在开发以支持更多文章"
            : "This article is not currently supported, and we are developing to support more articles",
      },
    });
  }, [languageType]);

  useEffect(() => {
    if (statusPrompt) {
      setColumns([
        {
          title: languageType == "cn" ? "标题" : "Title",
          dataIndex: "title",
          key: "title",
          width: 250,
          render: (text, record) => (
            <span
              style={{
                color: record.status == 10 && "dodgerblue",
                cursor: record.status == 10 && "pointer",
              }}
              onClick={() => toPaper(record)}
            >
              {text}
            </span>
          ),
        },
        {
          title: languageType == "cn" ? "DOI" : "DOI",
          dataIndex: "doi",
          key: "doi",
          width: 200,
          render: (text, record) => (
            <span
              style={{
                color: record.status == 10 && "dodgerblue",
                cursor: record.status == 10 && "pointer",
              }}
              onClick={() => toPaper(record)}
            >
              {text ? text : record.title}
            </span>
          ),
        },
        {
          title: languageType == "cn" ? "上传时间" : "Upload Time",
          dataIndex: "createdAt",
          key: "createdAt",
          width: 200,
          render: (text) => (
            <span>{moment(text).format("YYYY-MM-DD HH:mm:ss")}</span>
          ),
        },
        {
          title: languageType == "cn" ? "状态" : "Status",
          dataIndex: "status",
          key: "status",
          width: 150,
          render: (text, record) => <span>{analysisStatus(record)}</span>,
        },
      ]);
    }
  }, [statusPrompt]);

  const toPaper = (record) => {
    if (record.status == 10) {
      if (type == "doi") {
        navigate({
          pathname: `/evaluate/7`,
          search: `cid=${record.cid}&pid=${record.pid}&lang=${languageType}`,
        });
      } else {
        navigate({
          pathname: `/evaluate/${record.cid}`,
          search: `pid=${record.pid}`,
        });
      }
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows([...selectedRows]);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const tableToExcel = (jsonData) => {
    // 列标题，逗号隔开，每一个逗号就是隔开一个单元格
    let str = `上传时间,DOI,文章名,作者,期刊名,发表时间,文章引用,期刊影响,问题,结论,效果,实践方法,理论方法,创新评分\n`;
    // 增加\t为了不让表格显示科学计数法或者其他格式
    for (let i = 0; i < jsonData.length; i++) {
      for (const key in jsonData[i]) {
        str += `${jsonData[i][key] + "\t"},`;
      }
      str += "\n";
    }
    // encodeURIComponent解决中文乱码
    const uri = "data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(str);
    // 通过创建a标签实现
    const link = document.createElement("a");
    link.href = uri;
    // 对下载的文件命名
    link.download = "五维评分信息.csv";
    link.click();
    setSelectedRows([])
    setSelectedRowsAll([])
    setLoading(false);
    setIsModalOpen(false);
  };
  const handleOk = () => {
    setLoading(true);
    if (selectedRows.length == 0) {
      message.info(
        languageType == "cn"
          ? "请选择您要下载的数据"
          : "Please select the data you want to download"
      );
    } else {
      selectedRows.forEach((item, index) => {
        const { doi, cid, createdAt, title, updatedAt } = item;
        get({
          url: "/paper/analysis",
          params: {
            v: 3,
            doi,
            cid,
          },
          onSuccess: (data) => {
            if (data) {
              let info = JSON.parse(data);
              let authorStr = "";
              info.metadata?.author.map((it, idx) => {
                authorStr +=
                  it.full_name +
                  (idx + 1 != info.metadata.author.length ? "、" : "");
              });
              setJsonData({
                updatedAt: moment(updatedAt).format("YYYY-MM-DD HH:mm:ss"),
                doi: doi,
                title: title.replaceAll(",", "，"),
                author: authorStr,
                journal: info.metadata.journal,
                createdAt: moment(createdAt).format("YYYY-MM-DD HH:mm:ss"),
                citations: info.metadata.citations,
                impact_factor: info.metadata.impact_factor,
                question: info.rating.question.score,
                conclusion: info.rating.conclusion.score,
                effects: info.rating.effects.score,
                practical_method: info.rating.practical_method.score,
                theoretical_method: info.rating.theoretical_method.score,
                overall: info.rating.overall,
              });
            }
          },
        });
      });
    }
  };
  useEffect(() => {
    if (jsonData) {
      setSelectedRowsAll([...selectedRowsAll, jsonData]);
    }
  }, [jsonData]);
  useEffect(() => {
    if (selectedRowsAll.length != 0 && selectedRows.length == selectedRowsAll.length) {
      tableToExcel(selectedRowsAll);
    }
  }, [selectedRowsAll]);
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <br />
      <div>
        <label>{languageType == "cn" ? "视图筛选" : "View filtering"}：</label>
        <Radio.Group
          onChange={({ target: { value } }) => {
            setSelectionType(value);
          }}
          value={selectionType}
        >
          <Radio value={-1}>
            {languageType == "cn" ? "选择全部" : "Select all"}
          </Radio>
          <Radio value={10}>
            {languageType == "cn" ? "已完成" : "Completed"}
          </Radio>
          <Radio value={0}>
            {languageType == "cn" ? "进行中" : "Underway"}
          </Radio>
          <Radio value={20}>
            {languageType == "cn" ? "暂不支持" : "Do not support"}
          </Radio>
        </Radio.Group>
      </div>
      <Table
        columns={columns}
        dataSource={selectionType == -1 ? data : classifyData[selectionType]}
        pagination={{
          pageSize: 10,
          hideOnSinglePage: true,
          showSizeChanger: false,
          showQuickJumper: true,
        }}
        size="small"
      />
      <div className={styles.btn1}>
        <Button type="primary" onClick={showModal}>
          {languageType == "cn" ? "导出结果" : "Export results"}
        </Button>
      </div>

      <Modal
        width={900}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            {languageType == "cn" ? "下载" : "Download"}
          </Button>,
        ]}
      >
        <Table
          columns={columns}
          dataSource={classifyData[10]}
          pagination={{
            pageSize: 10,
            hideOnSinglePage: true,
            showSizeChanger: false,
            showQuickJumper: true,
          }}
          size="small"
          scroll={{
            y: 550,
          }}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
        />
      </Modal>
    </>
  );
}
