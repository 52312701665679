import React, { useEffect, useRef, useState } from "react";
import styles from "./index.scss";
import {
  Collapse,
  Typography,
  Input,
  Select,
  Spin,
  Tooltip,
  Button,
} from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import * as echarts from "echarts/core";
import { TooltipComponent, VisualMapComponent } from "echarts/components";
import { PieChart } from "echarts/charts";
import { LabelLayout } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { get, post } from "~/request";
import moment from "moment";
import { useLocation, useParams } from "react-router-dom";
import { exportPDF } from "../../../../common/exportPDF";
import ReactMarkdown from "react-markdown";
import { isNone } from "../../../../utils";
import PrChart from "./modules/prChart/prChart";
import PlanetMap from "./modules/planetMap/planetMap";
moment.locale("zh-cn");
const { Title, Paragraph, Text } = Typography;
const { TextArea } = Input;
echarts.use([
  TooltipComponent,
  VisualMapComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout,
]);

const ExamItems = ({ item, languageType }) => {
  return (
    <Typography>
      {item.abstract && (
        <>
          <Title level={4}>{languageType == "cn" ? "摘要" : "Abstract"}:</Title>
          <Paragraph style={{ fontSize: 16 }}>
            <div
              dangerouslySetInnerHTML={{
                __html: item.abstract,
              }}
            />
          </Paragraph>
        </>
      )}
      <Paragraph style={{ fontSize: 16 }}>
        <Text strong style={{ fontSize: 18 }}>
          DOI:
        </Text>
        &nbsp;{item.doi}
      </Paragraph>
      {item.author && item.author.length != 0 && !isNone(item.author) && (
        <>
          <Title level={4}>{languageType == "cn" ? "作者" : "Authors"}</Title>
          <Paragraph style={{ fontSize: 16 }}>
            {item.author.map((it, idx) => {
              return it.full_name + (idx + 1 != item.author.length ? ", " : '');
            })}
          </Paragraph>
        </>
      )}
      <div className={styles.details}>
        {item.pub_date && (
          <div>
            <Title level={4}>
              {languageType == "cn" ? "发表日期" : "Publication date"}
            </Title>
            <Paragraph style={{ fontSize: 16 }}>
              {moment(item.pub_date).format("YYYY-MM-DD")}
            </Paragraph>
          </div>
        )}
        <div>
          <Title level={4}>{languageType == "cn" ? "期刊" : "Journal"}</Title>
          <Paragraph style={{ fontSize: 16 }}>{item.journal}</Paragraph>
        </div>
        <div>
          <Title level={4}>
            {languageType == "cn" ? "出版社" : "Publisher"}
          </Title>
          <Paragraph style={{ fontSize: 16 }}>{item.publisher}</Paragraph>
        </div>
      </div>
    </Typography>
  );
};

const handleKey = (key) => {
  let tempKey = key.charAt(0).toUpperCase() + key.slice(1);
  return key.includes("_") ? tempKey.replace("_", " ") : tempKey;
};

function drawChart(id, data, languageType) {
  let myChart = echarts.getInstanceByDom(document.getElementById(id)); //有的话就获取已有echarts实例的DOM节点。
  if (myChart == null) {
    // 如果不存在，就进行初始化。
    myChart = echarts.init(document.getElementById(id));
  }
  let option = {
    tooltip: {
      trigger: "item",
    },
    visualMap: {
      show: false,
      min: 80,
      max: 600,
      inRange: {
        colorLightness: [0, 1],
      },
    },
    series: [
      {
        type: "pie",
        radius: "55%",
        center: ["50%", "50%"],
        data: [
          { value: data[0], name: languageType == "cn" ? "问题" : "Problem", itemStyle: { color: "#5470c6" } },
          { value: data[1], name: languageType == "cn" ? "理论" : "Theoretical", itemStyle: { color: "#91cc75" } },
          { value: data[2], name: languageType == "cn" ? "实践" : "Practical", itemStyle: { color: "#fac858" } },
          { value: data[3], name: languageType == "cn" ? "效果" : "Effects", itemStyle: { color: "#fc8452" } },
          { value: data[4], name: languageType == "cn" ? "结论" : "Conclusion", itemStyle: { color: "#73c0de" } },
        ],
        roseType: "area",
        label: {
          color: "rgba(0, 0, 0, 0.7)",
          fontSize: 15,
          fontFamily: "monospace",
          formatter: (params) => {
            return `${params.data.name}:${params.data.value}`;
          },
        },
        labelLine: {
          lineStyle: {
            color: "rgba(0, 0, 0, 0.2)",
          },
          smooth: 0.2,
          length: 10,
          length2: 20,
        },
        animationType: "scale",
        animationEasing: "elasticOut",
        animationDelay: function (idx) {
          return Math.random() * 200;
        },
      },
    ],
  };
  option && myChart.setOption(option);
  window.addEventListener("resize", () => {
    myChart.resize();
  });
}

export default function Analyze({ paperData, getInfo, languageType, user }) {
  const pdfRef = useRef();
  const [paper, setPaper] = useState();
  const [otherItems, setOtherItems] = useState([]); // 深度相关论文
  const [checkedPaper, setCheckedPaper] = useState(); // 选中的文章
  const [relevantPapersItems, setRelevantPapersItems] = useState([]); // 相关论文
  const [isSpread, setIsSpread] = useState(false); // 是否全部展开
  const [otherActiveKey, setOtherActiveKey] = useState([]); // 展开全部其他相关论文
  const [relevantActiveKey, setRelevantActiveKey] = useState([]); // 展开全部相关论文
  const [spinning, setSpinning] = useState(false);
  const [currentArticleItems, setCurrentArticleItems] = useState([]);
  const [info, setInfo] = useState();
  const [allOtherItems, setAllOtherItems] = useState([]); // 全部深度相关论文
  const [otherActiveKey1, setOtherActiveKey1] = useState([]); // 展开全部其他相关论文(All)
  const [isShowAllItems, setIsShowAllItems] = useState(false); // 是否全部展开其它相关论文(All)

  const [depthCorPapers, setDepthCorPapers] = useState([]); // 其他深度相关论文（穿透/行星图之外的）

  useEffect(() => {
    setInfo(null);
    setCurrentArticleItems([]);
    if (paperData) {
      // 获取分析报告
      get({
        url: "/paper/analysis",
        params: {
          v: 3,
          doi: paperData.doi,
          cid: paperData.cid,
        },
        onSuccess: (data) => {
          setInfo(JSON.parse(data));
        },
      });
      setPaper(paperData);
      // 获取相关论文
      let keys = [];
      get({
        url: "/paper/related",
        params: {
          doi: paperData.doi,
        },
        ignoreError: true,
        onSuccess: (data) => {
          let tempArr = [];
          JSON.parse(data).forEach((item, index) => {
            tempArr.push({
              key: index,
              label: item.title,
              children: <ExamItems item={item} languageType={languageType} />,
            });
            keys.push(`${index}`);
          });
          keys.length != 0 && setRelevantActiveKey(keys);
          setRelevantPapersItems(tempArr);
        },
      });
    } else {
      setPaper(null);
      setInfo(null);
    }
  }, [paperData]);

  const postScore = (param) => {
    post({
      url: "/paper/score",
      data: {
        pid: param.id,
        s1: Number(param.s1),
        s2: Number(param.s2),
        s3: Number(param.s3),
        s4: Number(param.s4),
        s5: Number(param.s5),
        remark: param.remark,
      },
      onSuccess: (data) => {
        setPaper({
          ...paperData,
          s1: Number(param.s1),
          s2: Number(param.s2),
          s3: Number(param.s3),
          s4: Number(param.s4),
          s5: Number(param.s5),
          remark: param.remark,
        });
      },
    });
  };

  const gradeClick = (value, text) => {
    paper[text] = value;
    postScore(paper);
  };

  const changeRemark = (e, text) => {
    paper[text] = e.target.value;
    postScore(paper);
  };

  const checkedPaperItem = (info) => {
    setCheckedPaper([
      {
        key: "1",
        label: (
          <strong>
            {languageType == "cn" ? "选中论文" : "Selected paper"}
          </strong>
        ),
        children: (
          <Typography>
            {info.similarity && (
              <Title level={4}>相似度：{info.similarity.toFixed(2)}</Title>
            )}
            <Title level={4}>{languageType == "cn" ? "标题" : "Title"}</Title>
            <Paragraph style={{ fontSize: 16 }}>
              {info.metadata.title}
            </Paragraph>
            <ExamItems item={info.metadata} languageType={languageType} />
          </Typography>
        ),
      },
    ]);
  };
  const evaluateItems = [
    {
      key: "1",
      label: "非常合理",
    },
    {
      key: "2",
      label: "合理",
    },
    {
      key: "3",
      label: "不合理",
    },
  ];
  const getNum = (text) => {
    switch (text) {
      case 0:
        return " ";
      case 1:
        return evaluateItems[0].label;
      case 2:
        return evaluateItems[1].label;
      case 3:
        return evaluateItems[2].label;
      default:
        break;
    }
  };
  const scoreKeys = {
    1: "s1",
    2: "s2",
    3: "s3",
    4: "s4",
    5: "s5",
  };

  // info数据展示
  useEffect(() => {
    if (info && paper) {
      console.log(info);
      getInfo(info);
      checkedPaperItem(info);
      // 五维创新评价
      if (info != 0) {
        let reports =
          languageType == "cn"
            ? info.report.split("##")
            : info.report_ENG.split("##");
        reports &&
          setCurrentArticleItems([
            {
              key: "1",
              label: (
                <strong>
                  {languageType == "cn"
                    ? "文章信息"
                    : "Basic Article Information"}
                </strong>
              ),
              children: (
                <Typography>
                  <Title level={4}>
                    {languageType == "cn" ? "标题" : "Title"}
                  </Title>
                  <Paragraph style={{ fontSize: 16 }}>
                    {info.metadata.title}
                  </Paragraph>
                  <Title level={4}>
                    {languageType == "cn" ? "摘要" : "Abstract"}
                  </Title>
                  <Paragraph style={{ fontSize: 16 }}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: info.metadata.abstract,
                      }}
                    />
                  </Paragraph>
                  <div className={styles.details}>
                    {info.metadata.author &&
                      info.metadata.author.length != 0 &&
                      !isNone(info.metadata.author) && (
                        <div>
                          <Title level={4}>
                            {languageType == "cn" ? "作者" : "Authors"}
                          </Title>
                          <Paragraph style={{ fontSize: 16 }}>
                            {info.metadata.author.map((it, idx) => {
                              return (
                                it.full_name +
                                (idx + 1 != info.metadata.author.length
                                  ? ", "
                                  : "")
                              );
                            })}
                          </Paragraph>
                        </div>
                      )}
                    <div>
                      <Title level={4}>DOI</Title>
                      <Paragraph style={{ fontSize: 16 }}>
                        {info.metadata.doi}
                      </Paragraph>
                    </div>
                    {info.summary.subject && (
                      <div style={{ minWidth: "max-content" }}>
                        <Title level={4}>
                          {languageType == "cn" ? "学科" : "Subject"}
                        </Title>
                        <Paragraph style={{ fontSize: 16 }}>
                          {info.summary.subject}
                        </Paragraph>
                      </div>
                    )}
                    {info.metadata.pub_date && (
                      <div>
                        <Title level={4}>
                          {languageType == "cn"
                            ? "发表日期"
                            : "Publication date"}
                        </Title>
                        <Paragraph style={{ fontSize: 16 }}>
                          {moment(info.metadata.pub_date).format("YYYY-MM-DD")}
                        </Paragraph>
                      </div>
                    )}
                    <div>
                      <Title level={4}>
                        {languageType == "cn" ? "期刊" : "Journal"}
                      </Title>
                      <Paragraph style={{ fontSize: 16 }}>
                        {info.metadata.journal}
                      </Paragraph>
                    </div>
                    <div>
                      <Title level={4}>
                        {languageType == "cn" ? "出版社" : "Publisher"}
                      </Title>
                      <Paragraph style={{ fontSize: 16 }}>
                        {info.metadata.publisher}
                      </Paragraph>
                    </div>
                    <div>
                      <Title level={4}>
                        {languageType == "cn"
                          ? "论文被引数"
                          : "Cited-by counts"}
                      </Title>
                      <Paragraph style={{ fontSize: 16 }}>
                        {info.metadata.citations
                          ? info.metadata.citations
                          : "N/A"}
                      </Paragraph>
                    </div>
                    <div>
                      <Title level={4}>
                        {languageType == "cn"
                          ? "期刊影响因子"
                          : "Journal Impact Factor"}
                      </Title>
                      <Paragraph style={{ fontSize: 16 }}>
                        {info.metadata.impact_factor
                          ? info.metadata.impact_factor
                          : "N/A"}
                      </Paragraph>
                    </div>
                    <div>
                      <Title level={4}>
                        {languageType == "cn" ? "期刊JCR分区" : "JCR Quartile"}
                      </Title>
                      <Paragraph style={{ fontSize: 16 }}>
                        {info.metadata.jcr_quantile
                          ? info.metadata.jcr_quantile
                          : "N/A"}
                      </Paragraph>
                    </div>
                  </div>
                </Typography>
              ),
            },
            {
              key: "2",
              label: (
                <strong>
                  {languageType == "cn"
                    ? "五维分析及原创性报告"
                    : "5-Dimensional Analysis and Novelty Report"}
                </strong>
              ),
              children: (
                <Typography>
                  <div style={{ height: 400 }}>
                    <div
                      id="quintuple"
                      style={{ width: "100%", height: "100%" }}
                    ></div>
                  </div>
                  {reports.map((item, index) => {
                    item = index != 0 ? "##" + item : item;
                    return (
                      <React.Fragment key={index}>
                        <ReactMarkdown>{item}</ReactMarkdown>
                        {index > 0 && index < reports.length - 1 && (
                          <div className={styles.isOk}>
                            {languageType == "cn"
                              ? "您觉得该部分评价是否合理？"
                              : "How will you rate this part of evaluation?"}
                            <Select
                              size="large"
                              value={getNum(paper[scoreKeys[index]])}
                              onChange={(value) =>
                                gradeClick(value, scoreKeys[index])
                              }
                              options={[
                                {
                                  value: "1",
                                  label:
                                    languageType == "cn"
                                      ? "非常合理"
                                      : "Very Reasonable",
                                },
                                {
                                  value: "2",
                                  label:
                                    languageType == "cn"
                                      ? "合理"
                                      : "Reasonable",
                                },
                                {
                                  value: "3",
                                  label:
                                    languageType == "cn"
                                      ? "不合理"
                                      : "Unreasonable",
                                },
                              ]}
                            />
                          </div>
                        )}
                      </React.Fragment>
                    );
                  })}
                  <div className={styles.evaluate}>
                    <p>
                      {languageType == "cn"
                        ? "您对本报告的主观评价"
                        : "Your comments to this analysis report."}
                    </p>
                    <TextArea
                      key={Math.random()}
                      rows={4}
                      defaultValue={paper.remark}
                      showCount={true}
                      onBlur={(e) => changeRemark(e, "remark")}
                    />
                  </div>
                </Typography>
              ),
            },
            {
              key: "3",
              label: (
                <strong>
                  {languageType == "cn"
                    ? "改进建议及多维度总结"
                    : "Improvement Suggestions and 5-dimensional summary."}
                </strong>
              ),
              children: (
                <>
                  <Paragraph style={{ fontSize: 16 }}>
                    <Title level={3}>
                      {languageType == "cn"
                        ? "改进建议"
                        : "Improvement Suggestions"}
                    </Title>
                    {info.rating.improve_suggest}
                  </Paragraph>
                  <Title level={3}>
                    {languageType == "cn"
                      ? "多维度总结"
                      : "5-dimensional summary"}
                  </Title>
                  {Object.keys(info.summary).map((key) => {
                    return (
                      <div key={key}>
                        {key != "summary" && (
                          <Paragraph key={key} style={{ fontSize: 16 }}>
                            <Text strong style={{ fontSize: 18 }}>
                              {handleKey(key)}
                            </Text>
                            :
                            {info.summary[key] == ""
                              ? info.rating[key].reason
                              : info.summary[key]}
                          </Paragraph>
                        )}
                      </div>
                    );
                  })}
                  {info.summary.summary != "" && (
                    <Paragraph style={{ fontSize: 16 }}>
                      <Text strong style={{ fontSize: 18 }}>
                        Summary
                      </Text>
                      :{info.summary.summary}
                    </Paragraph>
                  )}
                </>
              ),
            },
          ]);
      }

      // info中全部深度相关论文（下载pdf时全部展示）
      info.similar_papers.sort((a, b) => {
        return b.similarity - a.similarity;
      });
      let keys1 = [];
      let tempArr1 = [];
      let allOtherDatas = info.similar_papers;
      allOtherDatas.forEach((item, index) => {
        tempArr1.push({
          key: index + 1,
          label: (
            <>
              <strong>
                Similarity:&nbsp;
                {item.similarity.toFixed(2)}
              </strong>
              &nbsp;
              <div dangerouslySetInnerHTML={{ __html: item.metadata.title }} />
            </>
          ),
          children: <ExamItems item={item.metadata} />,
        });
        keys1.push(`${index + 1}`);
      });
      setOtherActiveKey1(keys1);
      tempArr1.length != 0 && setAllOtherItems([...tempArr1]);
    }
  }, [info, paper, languageType]);

  // 五维分析图
  useEffect(() => {
    if (info) {
      var chartDom = document.getElementById("quintuple");
      if (chartDom) {
        drawChart(
          "quintuple",
          [
            info.rating.question.score,
            info.rating.theoretical_method.score,
            info.rating.practical_method.score,
            info.rating.effects.score,
            info.rating.conclusion.score,
          ],
          languageType
        );
      }
    }
  }, [currentArticleItems, languageType]);

  const onExportPDF = () => {
    setIsShowAllItems(true); // 是否展示全部相关深度论文
    setIsSpread(true); // 展开所有tab
    // 不展示行星图，直接展示全部相关深度论文
    setSpinning(true); // 显示loading
    setTimeout(() => {
      exportPDF(info.metadata.doi, pdfRef.current, user.username);
    }, 1 * 1000);
    setTimeout(() => {
      setIsShowAllItems(false);
      setIsSpread(false);
      setSpinning(false);
    }, 15 * 1000);
  };

  useEffect(() => {
    if (depthCorPapers.length != 0) {
      depthCorPapers.sort((a, b) => {
        return b.similarity - a.similarity;
      });
      let keys = [];
      let tempArr = [];
      depthCorPapers.forEach((item, index) => {
        if (index < 8) {
          tempArr.push({
            key: index,
            label: (
              <>
                <strong>
                  {languageType == "cn" ? "相似度" : "Similarity"}:&nbsp;
                  {item.similarity.toFixed(2)}
                </strong>
                &nbsp;
                <div
                  dangerouslySetInnerHTML={{ __html: item.metadata.title }}
                />
              </>
            ),
            children: (
              <ExamItems item={info.metadata} languageType={languageType} />
            ),
          });
          keys.push(`${index + 1}`);
        }
      });
      setOtherActiveKey(keys);
      tempArr.length != 0 && setOtherItems([...tempArr]);
    }
  }, [depthCorPapers]);

  return (
    <div className={styles.analyze}>
      <div>
        <Spin
          tip="report is loading..."
          spinning={currentArticleItems.length == 0 || spinning}
        >
          <div ref={pdfRef} style={{ padding: "10px 5px" }}>
            <h2>
              {languageType == "cn" ? "五维创新评价" : "Competitive Analysis"}
            </h2>
            {currentArticleItems.length != 0 && (
              <Collapse
                items={currentArticleItems}
                defaultActiveKey={["1", "2", "3"]}
                activeKey={isSpread ? ["1", "2", "3"] : undefined}
                ghost
                expandIconPosition="end"
                size="large"
              />
            )}

            {!isSpread && (
              <>
                {/* 深度相关论文 */}
                <h2>
                  {languageType == "cn"
                    ? "深度相关论文"
                    : "Deeply related papers"}
                  &nbsp;
                  <Tooltip
                    title={
                      languageType == "cn"
                        ? "基于论文idea的高维向量相似度匹配"
                        : "High dimensional vector similarity matching based on idea"
                    }
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </h2>
                {/* 穿透引用图 */}
                <PrChart
                  info={info}
                  checkedPaperItem={checkedPaperItem}
                  setDepthCorPapers={setDepthCorPapers}
                />
                {/* 行星图 */}
                {/* <PlanetMap
                  info={info}
                  checkedPaperItem={checkedPaperItem}
                  setDepthCorPapers={setDepthCorPapers}
                  languageType={languageType}
                /> */}
                <Collapse
                  items={info && checkedPaper}
                  defaultActiveKey={["1"]}
                  activeKey={isSpread ? ["1"] : undefined}
                  ghost
                  expandIconPosition="end"
                  accordion={true}
                  size="large"
                />
              </>
            )}
            {/* 其他深度相关论文 */}
            {otherItems.length != 0 ? (
              <>
                <h2>
                  {languageType == "cn"
                    ? "其余深度相关论文"
                    : "The rest of the depth related papers"}
                  <span style={{ color: "grey" }}>
                    （{languageType == "cn" ? "部分" : "section"}）
                  </span>
                  <Tooltip
                    title={
                      languageType == "cn"
                        ? "基于论文idea的高维向量相似度匹配"
                        : "High dimensional vector similarity matching based on idea"
                    }
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </h2>
                <Collapse
                  className={styles.bot}
                  items={isShowAllItems ? allOtherItems : otherItems}
                  activeKey={
                    isSpread
                      ? isShowAllItems
                        ? otherActiveKey1
                        : otherActiveKey
                      : undefined
                  }
                  ghost
                  expandIconPosition="end"
                  size="large"
                />
              </>
            ) : (
              <Spin />
            )}
            {/* 相关论文 */}
            {relevantPapersItems.length != 0 && (
              <>
                <h2>
                  {languageType == "cn" ? "相关论文" : "Relevant papers"}
                  <span style={{ color: "grey" }}>
                    （{languageType == "cn" ? "部分" : "section"}）
                  </span>
                  <Tooltip
                    title={
                      languageType == "cn"
                        ? "传统关键词匹配技术结果（仅供对比）"
                        : "Results of traditional keyword matching techniques (for comparison only)"
                    }
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </h2>
                <Collapse
                  className={styles.bot}
                  items={relevantPapersItems}
                  activeKey={isSpread ? relevantActiveKey : undefined}
                  ghost
                  expandIconPosition="end"
                  size="large"
                />
              </>
            )}
          </div>
        </Spin>
      </div>

      <Button onClick={onExportPDF}>
        {languageType == "cn" ? "导出PDF" : "Export PDF"}
      </Button>
    </div>
  );
}
