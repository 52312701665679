import React, { useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "./pages/home/home";
import Subject from "./pages/subject/subject";
import UploadArticle from "./pages/uploadArticle/uploadArticle";

export default function Router({ languageType, user }) {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/home" element={<Home />}></Route>
        <Route
          path="/evaluate/:id"
          element={<Subject languageType={languageType} user={user} />}
        ></Route>
        <Route
          path="/upload"
          element={<UploadArticle languageType={languageType} />}
        ></Route>
      </Routes>
    </React.Fragment>
  );
}
