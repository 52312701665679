import React, { useEffect, useState } from "react";
import styles from "./index.scss";
import moment from "moment";
moment.locale("zh-cn");

// 行星图
export default function PlanetMap({ info, checkedPaperItem, setDepthCorPapers, languageType }) {
  const [checkedPaperTitle, setCheckedPaperTitle] = useState(); // 选中文章的title
  const [similarPapers, setSimilarPapers] = useState(); // 按年限划分深度相关论文

  useEffect(() => {
    if (info) {
      setCheckedPaperTitle(info.metadata.title);
      let tempStr = {
        inner: [],
        outer: [],
        others: [],
      };
      let currentYear = moment(info.metadata.pub_date).format("YYYY");
      info.similar_papers.sort((a, b) => {
        return b.similarity - a.similarity;
      });

      // 深度相关论文
      info.similar_papers.forEach((item, index) => {
        let itemYear = moment(item.metadata.pub_date).format("YYYY");
        if (itemYear >= currentYear - 2 && itemYear < currentYear) {
          if (tempStr.inner.length < 4) {
            tempStr.inner.push(item);
          } else {
            tempStr.others.push(item);
          }
        } else if (itemYear >= currentYear - 10 && itemYear < currentYear - 2) {
          if (tempStr.outer.length < 8) {
            tempStr.outer.push(item);
          } else {
            tempStr.others.push(item);
          }
        } else {
          tempStr.others.push(item);
        }
      });
      tempStr && setSimilarPapers({ ...tempStr });
      setDepthCorPapers([...tempStr.others]);
    }
  }, [info]);
  // 获取选中文章信息
  const selectedArticle = (article) => {
    setCheckedPaperTitle(article.metadata.title);
    checkedPaperItem(article);
  };
  return (
    <div className={styles.interaction}>
      <div className={styles.ring}>
        <div className={styles.innerCircle}>
          <div
            className={styles.center}
            onClick={() => selectedArticle(info)}
            style={{
              backgroundColor:
                checkedPaperTitle == info?.metadata.title && "magenta",
              color: checkedPaperTitle == info?.metadata.title && "white",
            }}
          >
            {languageType == "cn" ? "本文" : "This article"}
            <br />
            {moment(info?.metadata.pub_date).format("YYYY")}
          </div>
          {similarPapers &&
            similarPapers.inner.length != 0 &&
            similarPapers?.inner.map((it, idx) => {
              return (
                <div
                  className={styles.inner}
                  key={idx}
                  onClick={() => selectedArticle(it)}
                  style={{
                    backgroundColor:
                      checkedPaperTitle == it.metadata.title && "magenta",
                    color: checkedPaperTitle == it.metadata.title && "white",
                  }}
                >
                  <span>
                    {it.similarity.toFixed(2)},
                    <br />
                    {moment(it.metadata.pub_date).format("YYYY")}
                  </span>
                </div>
              );
            })}
        </div>
        {similarPapers &&
          similarPapers.outer.length != 0 &&
          similarPapers?.outer.map((it, idx) => {
            return (
              <div
                className={styles.outer}
                key={idx}
                onClick={() => selectedArticle(it)}
                style={{
                  backgroundColor:
                    checkedPaperTitle == it.metadata.title && "magenta",
                  color: checkedPaperTitle == it.metadata.title && "white",
                }}
              >
                <span>
                  {it.similarity.toFixed(2)},
                  <br />
                  {moment(it.metadata.pub_date).format("YYYY")}
                </span>
              </div>
            );
          })}
      </div>
    </div>
  );
}
