export function goto(url) {
  window.location.href = url;
}

let loadingCount = 0;
export function showLoading() {
  if (!loadingCount) {
    const el = document.createElement("div");
    el.id = "loading-mask";
    document.body.appendChild(el);
  }
  loadingCount += 1;
}

export function hideLoading() {
  loadingCount -= 1;
  if (!loadingCount) {
    document.getElementById("loading-mask").remove();
  }
}

export function getShape(id) {
  let el = document.getElementById(id);
  const width = el.offsetWidth;
  const height = el.offsetHeight;
  let top = el.offsetTop;
  let left = el.offsetLeft;
  while (el.offsetParent) {
    el = el.offsetParent;
    top += el.offsetTop;
    left += el.offsetLeft;
  }
  return { width, height, top, left };
}

// 解析search传参
export function getSearch(str) {
  if (str.indexOf("?") != -1) {
    let obj = {};
    str
      .split("?")[1]
      .split("&")
      .map((el) => {
        let arr = el.split("=");
        obj[arr[0]] = arr[1];
      });
    return obj;
  } else {
    return;
  }
}

export function clearToken() {
  localStorage.removeItem("X-Token");
  localStorage.removeItem("X-User-Id");
}

export function isNone(data) {
  return data === null || data === undefined;
}
