import React from "react";
import styles from "./index.scss";
import { Form, Input, Modal, Select } from "antd";

export default function Login({ open, onCreate, onCancel }) {
  const [form] = Form.useForm();
  const username = Form.useWatch("username", form);
  const password = Form.useWatch("password", form);
  return (
    <Modal
      open={open}
      title='账号密码登录'
      okText="登录"
      cancelButtonProps={{ style: { display: "none" } }}
      className={styles.loginModal}
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form form={form} layout="horizontal" name="form_in_modal">
        <Form.Item
          name="username"
          label="账号"
          rules={[
            {
              required: true,
              message: `请输入您的账号!`,
            },
          ]}
        >
          <Input
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
        <Form.Item
          name="password"
          label="密码"
          rules={[
            {
              required: true,
              message: `请输入您的密码!`,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
