import React, { useEffect, useState } from "react";
import styles from "./index.scss";
import { Tabs } from "antd";
import { get } from "~/request";
import PdfUpload from "./modules/pdfUpload";
import DoiUpload from "./modules/doiUpload";

export default function UploadArticle({ languageType }) {
  const [categories, setCategories] = useState([]);
  const [itemsTabs, setItemsTabs] = useState();
  const onChangeTabs = (key) => {
    console.log(key);
  };
  useEffect(() => {
    setItemsTabs([
      {
        key: "1",
        label: languageType == "cn" ? "上传DOI" : "Upload DOI",
        children: (
          <DoiUpload categories={categories} languageType={languageType} />
        ),
      },
      {
        key: "2",
        label: languageType == "cn" ? "上传PDF" : "Upload PDF",
        children: (
          <PdfUpload categories={categories} languageType={languageType} />
        ),
      },
    ]);
  }, [languageType]);
  useEffect(() => {
    get({
      url: "/paper/categories",
      onSuccess: (data) => {
        const { categories: tabs } = data;
        tabs.forEach((item) => {
          item["label"] = item.name;
          item["value"] = item.name;
        });
        setCategories(tabs);
      },
    });
  }, []);
  return (
    <div className={styles.uploadArticle}>
      <Tabs defaultActiveKey="1" items={itemsTabs} onChange={onChangeTabs} />
    </div>
  );
}
